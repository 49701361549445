import styled from "styled-components"

export default styled.div`
  display: flex;
  max-width: 1400px;
  min-height: 650px;
  width: 100%;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column;
  transition: padding 600ms;
  padding: 50px 0 0 0;

  .heroH1 {
    font-size: 10px;
    z-index: 2;
    margin: 0 0 16px 0;
    text-transform: uppercase;
    font-family: "interstate-compressed";
    font-weight: 800;
    letter-spacing: 2px;
  }

  .topline {
    transition: font-size 600ms;
    font-size: 2.4em;
    font-family: "Montserrat";
  }

  .headline {
    transition: font-size 600ms;
    line-height: 1em;
    font-size: 9.2em;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    min-height: 400px;
    width: 100%;
    padding: 0px;

    .topline {
      font-size: 1.6em;
    }

    .headline {
      font-size: 5em;
    }
  }

  @media (max-width: 1450px) {
    padding: 50px 50px 0 50px;
  }

  @media (max-width: 768px) {
    font-size: 2em;
    padding: 50px 50px 0 50px;
    margin: 0;
    align-items: flex-start;
  }
  @media (max-width: 580px) {
    padding: 50px 10px 0 10px;
  }
`
