import React from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"

const PointsStyled = styled.div `
  margin: 50px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1400px;

  @media (max-width: 768px) {
    padding: 10px;
    flex-wrap: wrap;
  }

  .point {
    text-align: center;
    text-decoration: none;
    display: inline-block;
    padding: 0 40px;
    width: 33%;
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 1.4em;
    color: ${p => p.theme.DarkBlue};

    .img {
      height: 100px;
      width: 100px;
    }

    .gatsby-image-wrapper {
      width: inherit;
      height: 100px;
    }
    .caption {
      max-width: 270px;
      padding: 20px 0;
    }
  }

  @media (max-width: 768px) {
    .point {
      margin: 0 auto;
      width: 100%;
    }
    .point .img {
      max-width: 100%;
    }
    .caption {
      padding: 4px 0;
    }
  }
`

const Points = ({fields}) => {
  return (<PointsStyled>
    {
      fields.points.length > 0
        ? fields.points.map((p, i) => {
          return (<div className="point" key={i}>
            <div className="img">
              <Img fixed={p.image.localFile.childImageSharp.fixed}/>
            </div>
            <div className="caption" tabIndex="0" dangerouslySetInnerHTML={{
                __html: p.caption
              }}/>
          </div>)
        })
        : ""
    }
  </PointsStyled>)
}

export default Points
