import React from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import SVG from "./svg"
import HeroRow from "./hero-row"
import Breadcrumbs from "./breadcrumbs"

const Col = styled.div`
  width: ${(p) => p.width};

  @media (max-width: 768px) {
    width: 100%;
  }
`

const HeroAreaStyled = styled.div`
  position: relative;
  height: 690px;
  overflow: hidden;
  max-width: 2560px;
  margin: 93px auto 0 auto;

  @media (max-width: 768px) {
    /* min-height: 720px; */
  }

  .background {
    display: flex;
    justify-conent: center;
    align-items: flex-start;
    overflow: hidden;
    max-width: 2560px;
    height: 690px;
    margin: 0 auto;
    top: 0;
    z-index: 1;
    position: absolute;
    width: 100%;
  }

  .gatsby-image-wrapper {
    width: 100%;
    min-height: 690px;
  }

  .topline {
    color: ${(p) => p.fields.hero_area.top_line_color || p.theme.DarkBlue};
  }

  .headline {
    color: ${(p) => p.fields.hero_area.headline_color || p.theme.White};
  }
`

const HeroArea = ({ fields, children, trail }) => {
  const sources = fields.hero_area.mobile_background
    ? [
        {
          media: "(min-width: 769px)",
          ...fields.hero_area.background.localFile.childImageSharp.fluid,
        },
        {
          media: "(max-width: 768px)",
          ...fields.hero_area.mobile_background.localFile.childImageSharp.fluid,
        },
      ]
    : fields.hero_area.background
    ? [
        {
          ...fields.hero_area.background.localFile.childImageSharp.fluid,
        },
      ]
    : []
  return (
    <HeroAreaStyled fields={fields}>
      <div className="background">
        {fields.hero_area.background ? (
          <Img
            objectFit="cover"
            objectPosition="50% 100%"
            alt={fields.hero_area.headline}
            imgStyle={{
              zIndex: 1,
            }}
            fluid={sources}
          />
        ) : (
          ""
        )}
      </div>
      <HeroRow fields={fields}>
        {trail ? (
          <Breadcrumbs trail={trail} color={fields.hero_area.top_line_color} />
        ) : (
          ""
        )}
        <h1 className="heroH1">
          <div
            className="topline"
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: fields.hero_area.top_line,
            }}
          />
          <div
            className="headline"
            dangerouslySetInnerHTML={{
              __html: fields.hero_area.headline,
            }}
            tabIndex="0"
          />
          <SVG name="SWave" color={fields.hero_area.wave_color} />
        </h1>
        {children}
      </HeroRow>
    </HeroAreaStyled>
  )
}

export default HeroArea
