import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import throttle from "../utils/throttle"
import styled, { ThemeContext } from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"
import Center from "../components/center"
import Heading from "../components/heading"
import HeroArea from "../components/hero"
import Carousel from "../components/carousel"
import Certs from "../components/ingredients/certs"
import BottomColorNav from "../components/bottom-color-nav"
import PintDetails from "../components/sustainability/pint-details"
import Points from "../components/sustainability/points"
import RoughEdge from "../components/rough-edge"
import { TextBox, ImageBox } from "../pages/ingredients"
import { Controller, Scene } from "react-scrollmagic"

const INDICATOR_ENABLE = false // For Scrollmagic debugging
const SLIDE_TRANSITION_AT = 0.4
const HEIGHT = 100

const EdgeContStyled = styled.div`
  padding: 0px 0;
  position: relative;
  z-index: 1;
`

const WhiteBG = ({ children, ...rest }) => {
  return <WhiteBGStyled {...rest}>{children}</WhiteBGStyled>
}

const Sustainability = ({ fields }) => {
  const theme = useContext(ThemeContext)

  const {
    top_panels,
    bottom_panels,
    hero_area,
    opening,
    pint_details,
    certifications,
    carousel,
  } = fields

  const trail = [
    {
      label: "ABOUT US",
      link: "/about-us/",
    },
    {
      label: hero_area.breadcrumb_title,
      link: "",
    },
  ]
  return (
    <SustainabilityStyled>
      <HeroArea fields={fields} trail={trail} />
      <EdgeContStyled>
        <RoughEdge className="top" color={theme.White} />
        <WhiteBG padding="50px 0">
          <Heading margin="0">
            <h2
              tabIndex="0"
              dangerouslySetInnerHTML={{
                __html: opening.headline,
              }}
            />
            <h3
              tabIndex="0"
              dangerouslySetInnerHTML={{
                __html: opening.sub_heading,
              }}
            />
            <p
              tabIndex="0"
              dangerouslySetInnerHTML={{
                __html: opening.summary,
              }}
            />
          </Heading>
          <Points fields={fields.opening} />
        </WhiteBG>
      </EdgeContStyled>
      <Controller refreshInterval={false}>
        <Scene
          duration={"150%"}
          pin={true}
          triggerHook={"onLeave"}
          pinSettings={{
            pushFollowers: true,
          }}
          indicators={INDICATOR_ENABLE}
        >
          {(progress) => {
            return (
              <section
                className={`${
                  progress < SLIDE_TRANSITION_AT ? "slide-1" : "slide-2"
                }`}
              >
                <div className="column left">
                  <div className="panel _2">
                    <TextBox fields={top_panels[0]} align="left" />
                  </div>
                  <div className="panel _3">
                    <ImageBox fields={top_panels[1]} align="left" />
                  </div>
                </div>
                <div className="column right">
                  <div className="panel _2">
                    <TextBox fields={top_panels[1]} align="left" />
                  </div>
                  <div className="panel _3">
                    <ImageBox fields={top_panels[0]} align="left" />
                  </div>
                </div>
              </section>
            )
          }}
        </Scene>
      </Controller>
      <WhiteBG padding="50px 0">
        <Heading margin="0 0 50px 0">
          <h2
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: certifications.headline,
            }}
          />
        </Heading>
        <Certs
          fields={certifications.logos}
          color={certifications.logo_color}
        />
      </WhiteBG>
      <div className="pintDetailCont">
        <Heading margin="50px 0">
          <h2
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: pint_details.headline,
            }}
          />
        </Heading>
        <PintDetails fields={pint_details} />
      </div>
      <Controller refreshInterval={false}>
        <Scene
          duration={"150%"}
          pin={true}
          triggerHook={"onLeave"}
          pinSettings={{
            pushFollowers: true,
          }}
          indicators={INDICATOR_ENABLE}
        >
          {(progress) => {
            return (
              <section
                className={`${
                  progress < SLIDE_TRANSITION_AT ? "slide-1" : "slide-2"
                }`}
              >
                <div className="column left">
                  <div className="panel _2">
                    <TextBox fields={bottom_panels[0]} align="left" />
                  </div>
                  <div className="panel _3">
                    <ImageBox fields={bottom_panels[1]} align="left" />
                  </div>
                </div>
                <div className="column right">
                  <div className="panel _2">
                    <TextBox fields={bottom_panels[1]} align="left" />
                  </div>
                  <div className="panel _3">
                    <ImageBox fields={bottom_panels[0]} align="left" />
                  </div>
                </div>
              </section>
            )
          }}
        </Scene>
      </Controller>
      <WhiteBG padding="150px 0 0 0" mobilePadding="20px 0 0 0">
        <Heading margin="0">
          <h2
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: carousel.headline,
            }}
          />
          <h3
            tabIndex="0"
            dangerouslySetInnerHTML={{
              __html: carousel.sub_heading,
            }}
          />
        </Heading>
        <Carousel />
        <Center margin="0 0 150px 0" mobileMargin="0 0 50px 0">
          <Button to={"/flavors"}>{carousel.button_label}</Button>
        </Center>
      </WhiteBG>
    </SustainabilityStyled>
  )
}

const SustainabilityPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { slug: { eq: "sustainability-page" } }) {
        edges {
          node {
            acf {
              hero_area {
                breadcrumb_title
                top_line
                headline
                headline_color
                top_line_color
                wave_color
                background {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 2560, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                mobile_background {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
              opening {
                headline
                summary
                points {
                  image {
                    localFile {
                      childImageSharp {
                        fixed(height: 100, quality: 100) {
                          ...GatsbyImageSharpFixed_withWebp
                        }
                      }
                    }
                  }
                  caption
                }
              }
              top_panels {
                color_bg
                image_bg {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1440, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                mobile_image_bg {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                headline
                sub_heading
                summary
                points {
                  item
                }
              }
              carousel {
                headline
                sub_heading
                button_label
              }
              certifications {
                logo_color
                headline
                logos {
                  heading
                  summary
                  svg_name
                }
              }
              pint_details {
                headline
                summary
                pint_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 300, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                points {
                  alt_text
                  item {
                    localFile {
                      childImageSharp {
                        fixed(height: 65) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
              bottom_panels {
                logo
                color_bg
                image_bg {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                mobile_image_bg {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                headline
                sub_heading
                summary
              }
              search_title
              search_description
              share_headline
              share_comment
              share_image {
                source_url
              }
            }
          }
        }
      }
    }
  `)

  const fields = data.allWordpressPage.edges[0].node.acf
  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields
  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <Sustainability fields={fields} />
      <BottomColorNav />
    </Layout>
  )
}

const SustainabilityStyled = styled.div`
  .pintDetailCont {
    background: ${(p) => p.theme.LightestBlue};
    padding: 20px 0;
  }

  .fg {
    top: 0;
    position: relative;
    opacity: 1;
    z-index: 2;
  }

  .sectionContainer {
    position: relative;
  }

  section {
    position: relative;
    z-index: 2;
    top: 0;
    width: 100vw;
    height: ${HEIGHT}vh;
    overflow: hidden;
  }

  .column {
    position: relative;
    top: 0;
    height: inherit;
    width: 50vw;
    transition: transform 600ms ease-out;
  }

  .left {
    left: 0;
  }

  .right {
    left: 50%;
  }

  .slide-1 {
    .left {
      transform: translateY(0vh);
    }
    .right {
      transform: translateY(-200vh);
    }
  }

  .slide-2 {
    .left {
      transform: translateY(-100vh);
    }
    .right {
      transform: translateY(-100vh);
    }
  }

  .panel {
    height: ${HEIGHT}vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const WhiteBGStyled = styled.div`
  background: ${(p) => p.theme.White};
  padding: ${(p) => p.padding || "0px"};
  @media (max-width: 768px) {
    padding: ${(p) => p.mobilePadding || "0px"};
  }
`

export default SustainabilityPage
