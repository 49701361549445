import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"

const PointStyled = styled.div`
  position: absolute;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .pointer {
    position: absolute;
    width: 80px;
    @media (max-width: 768px) {
      display: none;
    }
  }

  &.top-left {
    top: 50px;
    left: -40px;

    .infoPopin {
      top: -65px;
      right: 380px;
      height: 72px;
    }

    .pointer {
      top: -40px;
      left: -90px;
      transform: scale(1, 1);
    }
  }

  &.top-right {
    top: 50px;
    right: -40px;

    .infoPopin {
      top: -75px;
      left: 125px;
      width: 300px;
    }

    .pointer {
      top: -40px;
      right: -90px;
      transform: scaleX(-1) rotate(0deg);
    }
  }

  &.bottom-left {
    bottom: 50px;
    left: -30px;

    @media (max-width: 768px) {
      top: 170px;
    }

    .infoPopin {
      bottom: -35px;
      right: 315px;
      width: 300px;
    }

    .pointer {
      bottom: -40px;
      left: -90px;
      width: 80px;
      transform: scaleY(-1);
    }
  }

  &.bottom-right {
    bottom: 50px;
    right: -30px;

    @media (max-width: 768px) {
      top: 170px;
    }

    .infoPopin {
      bottom: -50px;
      left: 120px;
      width: 300px;
    }

    .pointer {
      bottom: -40px;
      right: -90px;
      width: 80px;
      transform: scaleY(1) rotate(180deg);
    }
  }

  .pointer {
    opacity: 0;
    transition: opacity 500ms;
  }

  .infoPopin {
    position: absolute;
    max-width: 329px;
    width: 100%;
    opacity: 0;
    transition: opacity 500ms;

    @media (max-width: 768px) {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
    }
  }

  .popup {
    position: absolute;
    width: 30px;
    height: 30px;
    background: ${(p) => p.theme.Blue};
    border-radius: 100%;

    &:before,
    &:after {
      content: "";
      position: absolute;
      background-color: white;
      transition: transform 0.25s ease-out;
    }
    &:before {
      transform: rotate(0deg);
    }
    &:after {
      transform: rotate(0deg);
    }

    /* Vertical line */
    &:before {
      top: 20%;
      left: 52%;
      width: 3px;
      height: 60%;
      margin-left: -2px;
    }

    /* horizontal line */
    &:after {
      top: 50%;
      left: 20%;
      width: 60%;
      height: 3px;
      margin-top: -2px;
    }
  }

  &.active {
    cursor: pointer;
    .popup:before {
      transform: rotate(90deg);
    }
    .popup:after {
      transform: rotate(180deg);
    }
    .infoPopin {
      opacity: 1;
    }
    .pointer {
      opacity: 1;
      transition-delay: 300ms;
    }
  }
`

const Point = ({ className, children, active, setActive, index }) => {
  const c = `${active[index] ? "active" : ""} ${className}`
  const newActive =
    typeof window !== "undefined" && window.innerWidth > 768
      ? [...active]
      : [false, false, false, false]
  newActive[index] = !newActive[index]
  return (
    <PointStyled onClick={(e) => setActive(newActive)} className={c}>
      {children}
    </PointStyled>
  )
}

const PintDetailsStyled = styled.div`
  width: 340px;
  position: relative;
  margin: 75px auto 200px auto;

  .img {
    margin: 0 auto;
    height: 310px;
    width: 293px;
  }

  .pointsMobile {
    display: none;
    @media (max-width: 768px) {
      margin: 50px 0 0 0;
      display: block;
      position: relative;

      .popup {
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        transition: opacity 500ms;
        opacity: 0;
        width: 100%;
      }
      .popup.active {
        opacity: 1;
      }
      .infoPopin {
        margin: 0 auto;
      }
    }
  }
`

const PintDetails = ({ fields }) => {
  const allFalse = [false, false, false, false]
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        setActive(allFalse)
      },
      false
    )
    return () => {
      window.removeEventListener("resize", () => {
        setActive(allFalse)
      })
    }
  }, [])
  const { pint_image, points } = fields
  const [active, setActive] = useState(allFalse)
  const classNames = ["top-left", "top-right", "bottom-left", "bottom-right"]
  return (
    <PintDetailsStyled>
      <Img className="img" fluid={pint_image.localFile.childImageSharp.fluid} />{" "}
      {points.map((p, i) => {
        return (
          <Point
            key={classNames[i]}
            className={classNames[i]}
            active={active}
            setActive={setActive}
            index={i}
          >
            <div className="popup">
              <Img
                alt={p.alt_text}
                className="infoPopin"
                loading="eager"
                fixed={p.item.localFile.childImageSharp.fixed}
              />
              <img
                src="/Arrow.png"
                alt=""
                role="decorative"
                className="pointer"
              />
            </div>
          </Point>
        )
      })}
      <div className="pointsMobile">
        {points.map((p, i) => {
          return (
            <div key={i} className={`popup ${active[i] ? "active" : ""}`}>
              <Img
                className="infoPopin"
                loading="eager"
                fixed={p.item.localFile.childImageSharp.fixed}
              />
            </div>
          )
        })}
      </div>
    </PintDetailsStyled>
  )
}

export default PintDetails
